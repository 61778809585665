// This is provided as a Webpack UMD entry

import { parsers } from '@sx/advisergw-url-manager';

// Controlling the page title is a bit tricky with MFEs. There are few practices around how this can be done.
// Given we stick to the rule that the application state is driven by the URL (or is always reflected in the URL), we
// can solely base the page title on the URL.

const providers: ((location: Location) => string | undefined)[] = [
  (location) => {
    const res = parsers.auth(location);
    if (!res?.action) {
      return undefined;
    }
    return {
      'post-log-out': 'Auth | Adviser Hub',
      'post-log-in': 'Auth | Adviser Hub',
      'log-in-migrate': 'Auth | Adviser Hub',
      'log-in': 'Auth | Adviser Hub',
      'change-password': 'Auth | Adviser Hub',
      'log-out': 'Auth | Adviser Hub',
      'session-expired': 'Session has expired | Adviser Hub',
      'session-ended': 'Session ended | Adviser Hub',
      error: 'Error | Adviser Hub',
    }[res.action];
  },

  (location) => {
    const res = parsers.legalAgreements(location);
    if (!res?.type) {
      return undefined;
    }
    return {
      'privacy-statement': `Privacy Statement | Adviser Hub`,
      'terms-of-use': `Terms of Use | Adviser Hub`,
    }[res.type];
  },

  (location) => (parsers.migrateMe(location) ? 'Register for Adviser Hub' : undefined),

  (location) => (parsers.landing(location) ? 'Adviser Hub' : undefined),

  (location) => (parsers.dashboard(location) ? 'Home | Adviser Hub' : undefined),

  (location) => (parsers.faq(location) ? 'Frequently Asked Questions | Adviser Hub' : undefined),

  (location) => {
    const res = parsers.groupDetails(location);
    return res ? `Group ${res.groupId} | Adviser Hub` : undefined;
  },

  (location) => (parsers.groups(location) ? 'Groups | Adviser Hub' : undefined),

  (location) => {
    const res = parsers.policyDetails(location);
    return res ? `Policy ${res.subscriberId} | Adviser Hub` : undefined;
  },

  (location) => (parsers.policies(location) ? 'Policies | Adviser Hub' : undefined),

  () => 'Page not found | Adviser Hub',
];

const handlePop = (): void => {
  document.title = providers.find((provider) => provider(window.location))?.(window.location) || 'Adviser Hub';
};

// let's set the initial one
handlePop();

// and listen to the changes
window.addEventListener('popstate', handlePop);
